import { n as C, C as k, c as x, a as h } from "./article-srUBAy2r.mjs";
import { s as a } from "./styled-egLbU9Lr.mjs";
import { jsxs as m, jsx as f } from "react/jsx-runtime";
import { Box as y } from "@mui/system";
import p, { useImperativeHandle as b, useEffect as v } from "react";
import { cssToken as T } from "./utils.mjs";
const B = a("em", { name: C, slot: "Accent" })({
  fontStyle: "normal",
  color: k.accentColor()
}), R = a(y, { name: "Collapse", slot: "root" })({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  flex: "1 1 0"
}), E = a(y, { name: "Collapse", slot: "container" })({
  flex: "1 1 0"
}), F = ({ open: e, header: t, footer: o, children: i, ...n }) => /* @__PURE__ */ m(R, { ...n, children: [
  t,
  /* @__PURE__ */ f(E, { sx: { overflow: e ? "visible" : "hidden" }, children: i }),
  o
] }), q = a("img")({ border: 0, display: "block", color: "transparent" }), P = a("div", { name: x, slot: "root" })({
  maxWidth: h.width(),
  marginLeft: "auto",
  marginRight: "auto",
  boxSizing: "content-box",
  paddingInlineStart: h.offset(),
  paddingInlineEnd: h.offset(),
  containerType: "inline-size"
});
P.displayName = x;
const I = "Details", $ = a("details", { name: I })({
  "& > summary": {
    display: "flex",
    flexFlow: "row nowrap"
  },
  "& > summary::-webkit-details-marker": {
    display: "none"
  },
  "& > summary::marker": {
    display: "none"
  }
}), G = ({ open: e = !1, children: t, summary: o, marker: i, renderMarker: n = () => null, ...r }) => {
  const [l, s] = p.useState(e);
  return /* @__PURE__ */ m(
    $,
    {
      open: l || e,
      onToggle: (c) => {
        s(c.currentTarget.open);
      },
      ...r,
      children: [
        /* @__PURE__ */ m("summary", { children: [
          o,
          /* @__PURE__ */ m("span", { className: "Details-Marker", children: [
            i,
            n(l)
          ] })
        ] }),
        t
      ]
    }
  );
}, g = (e, t) => {
  e.style.width = t.width + "px", e.style.height = t.height + "px";
}, D = (e) => e.height / e.width, w = (e, t) => {
  const o = t.width || e.width, i = o * D(e);
  return { width: o, height: i };
}, u = "VideoPlayer", z = {
  stageColor: T(u, ["stage", "color"])
}, V = a(y, { name: u })({ overflow: "hidden", backgroundColor: z.stageColor() }), W = a("video", { name: u, slot: "Video" })({
  display: "block",
  width: "100%"
}), j = p.forwardRef(({ sx: e, controlsList: t, ...o }, i) => {
  const n = p.useRef(null), r = p.useRef(null), l = (t || "").split(" ");
  return b(i, () => ({
    play() {
      return r.current ? r.current.play() : Promise.reject(new Error("Player not ready"));
    },
    pause() {
      return r.current ? Promise.resolve(r.current.pause()) : Promise.reject(new Error("Player not ready"));
    }
  })), v(() => {
    const s = () => {
      const c = n.current, d = r.current;
      !d || !c || g(d, w({ width: d.videoWidth, height: d.videoHeight }, { width: c.offsetWidth }));
    };
    return window.addEventListener("resize", s), () => window.removeEventListener("resize", s);
  }, []), /* @__PURE__ */ f(V, { ref: n, sx: e, children: /* @__PURE__ */ f(
    W,
    {
      onLoadedMetadata: (s) => {
        n.current && g(
          s.currentTarget,
          w(
            {
              width: s.currentTarget.videoWidth,
              height: s.currentTarget.videoHeight
            },
            { width: n.current.offsetWidth }
          )
        );
      },
      controlsList: t,
      playsInline: l.includes("nofullscreen"),
      "x-webkit-airplay": l.includes("noremoteplayback") ? "deny" : "allow",
      disableRemotePlayback: l.includes("noremoteplayback"),
      ref: r,
      ...o
    }
  ) });
});
j.displayName = u;
export {
  F as C,
  G as D,
  q as I,
  B as T,
  j as V,
  P as a,
  z as b
};
