import { styled } from '@cp/ds/theme';
import { Typography, TypographyProps } from '@cp/ds/components/typography';
import { CssTokens } from './vars';

const Root = styled(Typography)(({ theme }) => ({
  display: 'block',
  margin: 0,
  paddingBlockEnd: CssTokens.itemHeaderOffset(),
  color: theme.palette['brand gray'],
}));
export const FooterItemHeader = (props: TypographyProps) => <Root component="h6" variant="button secondary" {...props} />;
