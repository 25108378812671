import React, { FC, ReactNode } from 'react';
import { styled } from '@cp/ui/theme';
import { Grid } from '@cp/ds/components/grid';
import { RoutedLink } from 'src/shared/ui/link';
import { Schema } from '@cp/ds/components/schema';
import { getOrganzationSchema } from '@youhelp/client.web/app/shared/schemas/organization';
import { Box, BoxProps } from '@cp/ui/components';
import { useTrademarkComponent } from '@cp/entities/trademark';
import { Typography } from '@cp/ds/components/typography';
import { StyledUnorderedList } from '@cp/ds/components/styled/styledUnorderedList';
import { StyledListItem } from '@cp/ds/components/styled/styledListItem';
import { Section } from '#shared/ui/section';
import { useHomeHref } from '#features/home';
import { useAboutMenuItems, useUsersMenuItems } from '../model';
import { SupportSection } from './supportSection';
import { Accordion } from './accordion';
import { FooterBottom } from './footerBottom';
import { FooterItemHeader } from './itemHeader';
import { CssTokens, name } from './vars';

const Root = styled(Box, { name })({
  [CssTokens.itemHeaderOffset]: '16px',
  background: 'rgba(224, 234, 240, 1)',
});

const GridRoot = styled(Box, { name, slot: 'GridRoot' })(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: ['"trademark"', '"specialistMenu"', '"forUsersMenu"', '"aboutUsMenu"', '"support"'].join(' '),
  gap: '16px',
  '@container (min-width: 600px)': {
    gridTemplateAreas: ['"trademark trademark"', '"specialistMenu specialistMenu"', '"forUsersMenu aboutUsMenu"', '"support support"'].join(
      ' ',
    ),
  },
  '@container (min-width: 890px)': {
    gridTemplateAreas: ['"trademark specialistMenu specialistMenu"', '"forUsersMenu aboutUsMenu support"'].join(' '),
  },
}));

export interface FooterProps extends BoxProps {
  specialistMenu?: ReactNode;
}
export const Footer: FC<FooterProps> = ({ specialistMenu, ...props }) => {
  const home = useHomeHref();
  const Trademark = useTrademarkComponent();
  const aboutMenu = useAboutMenuItems();
  const userMenu = useUsersMenuItems();

  return (
    <Root component="footer" {...props}>
      <Section>
        <GridRoot>
          <Box sx={{ gridArea: 'trademark', borderBottom: '1px solid' }}>
            <RoutedLink href={home} sx={{ textDecoration: 'none' }}>
              <Trademark />
            </RoutedLink>
          </Box>
          <Box sx={{ gridArea: 'specialistMenu' }}>
            {specialistMenu && (
              <Grid flexDirection={{ xs: 'column', md: 'row' }} item lg={10.6} xl={9.2} xs={12}>
                {specialistMenu}
              </Grid>
            )}
          </Box>
          <Box sx={{ gridArea: 'forUsersMenu' }}>
            <Accordion summary={<FooterItemHeader>{userMenu.title}</FooterItemHeader>}>
              <StyledUnorderedList>
                {userMenu.items.map(({ link, label, external }, i) => (
                  <StyledListItem key={`${label}-${i}`} sx={{ py: { xs: 1, md: 0.5 } }}>
                    <RoutedLink external={external} href={link} sx={{ color: 'gray' }} underline={false}>
                      <Typography variant="body bold">{label}</Typography>
                    </RoutedLink>
                  </StyledListItem>
                ))}
              </StyledUnorderedList>
            </Accordion>
          </Box>
          <Box sx={{ gridArea: 'aboutUsMenu' }}>
            <Accordion summary={<FooterItemHeader>{aboutMenu.title}</FooterItemHeader>}>
              <StyledUnorderedList>
                {aboutMenu.items.map(({ link, label, external }, i) => (
                  <StyledListItem key={`${label}-${i}`} sx={{ py: { xs: 1, md: 0.5 } }}>
                    <RoutedLink external={external} href={link} sx={{ color: 'gray' }} underline={false}>
                      <Typography variant="body bold">{label}</Typography>
                    </RoutedLink>
                  </StyledListItem>
                ))}
              </StyledUnorderedList>
            </Accordion>
          </Box>
          <SupportSection sx={{ gridArea: 'support' }} />
        </GridRoot>
      </Section>
      <FooterBottom />
      <Schema schema={getOrganzationSchema()} />
    </Root>
  );
};
